import React from 'react'
import PropTypes from 'prop-types'

const UmbrelaIcon = ({className}) => (
  <svg className={className} height="55px" width="100%" viewBox="0 0 61 109" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="umbrela">
        <path
          d="M40.761,9.869 C42.552,7.337 41.518,2.235 39.798,0.37 C39.774,0.53 39.58,0.519 39.384,0.508 C37.707,2.551 35.291,10.691 40.761,9.869"
          id="Fill-450"
          fill="#1E1D1B"
        />
        <path
          d="M18.322,14.411 C22.933,14.523 20.333,3.91 17.91,3.673 C16.291,5.328 14.685,14.322 18.322,14.411"
          id="Fill-451"
          fill="#1E1D1B"
        />
        <path
          d="M33.603,18.955 C34.038,15.854 33.157,11.612 31.813,9.73 C31.722,9.73 31.629,9.73 31.539,9.73 C31.324,9.931 31.001,10.019 30.711,10.144 C30.153,12.162 28.47,15.553 29.06,18.541 C29.538,20.961 33.248,21.489 33.603,18.955"
          id="Fill-452"
          fill="#1E1D1B"
        />
        <path
          d="M50.123,19.918 C51.91,17.182 50.46,12.562 48.746,10.832 C47.006,12.841 44.434,20.852 50.123,19.918"
          id="Fill-453"
          fill="#1E1D1B"
        />
        <path
          d="M3.317,18.955 C1.982,20.942 -0.453,30.11 4.143,29.553 C8.047,29.082 4.464,19.934 3.73,18.678 C3.695,18.871 3.427,18.836 3.317,18.955"
          id="Fill-454"
          fill="#1E1D1B"
        />
        <path
          d="M23.278,30.381 C24.384,30.309 24.644,29.463 25.206,28.59 C25.414,25.614 24.284,24.006 23.828,21.844 C23.691,21.188 24.028,20.399 23.278,19.918 C23.232,19.918 23.187,19.918 23.14,19.918 C21.611,21.58 18.687,30.674 23.278,30.381"
          id="Fill-455"
          fill="#1E1D1B"
        />
        <path
          d="M38.697,31.205 C42.467,31.778 41.757,23.483 38.972,22.672 C38.281,23.096 36.9,25.75 36.769,26.94 C36.578,28.682 37.46,31.018 38.697,31.205"
          id="Fill-456"
          fill="#1E1D1B"
        />
        <path
          d="M14.605,23.496 C14.572,23.694 14.378,23.729 14.33,23.91 C12.69,25.139 11.437,33.364 15.156,32.721 C15.892,32.592 16.945,31.131 17.083,30.381 C17.408,28.618 15.818,23.641 14.605,23.496"
          id="Fill-457"
          fill="#1E1D1B"
        />
        <path
          d="M57.556,40.705 C58.009,40.573 58.953,39.141 59.07,38.639 C59.704,35.946 57.71,30.672 56.592,30.243 C55.365,30.989 54.093,35.164 54.527,37.815 C54.74,39.114 56.002,41.157 57.556,40.705"
          id="Fill-458"
          fill="#1E1D1B"
        />
        <path
          d="M59.483,59.428 C60.009,58.979 60,58.483 60.034,57.776 C60.247,53.405 58.15,49.918 56.318,47.037 C51.695,39.766 45.669,33.664 34.154,33.545 C34.07,31.748 34.247,29.69 33.052,29.004 C32.481,28.803 31.997,29.873 31.4,30.106 C30.993,31.205 31.378,32.733 31.125,33.684 C23.714,33.328 19.16,35.317 14.193,37.815 C7.977,40.94 4.011,45.678 1.252,51.854 C1.025,53.875 0.654,57.069 1.252,59.014 C-1.12,60.832 3.257,63.815 3.179,60.116 C4.531,57.979 7.416,57.375 11.164,57.637 C12.371,58.174 13.126,59.162 13.779,60.252 C13.236,60.461 12.963,61.717 13.503,62.18 C15.398,62.948 14.806,61 15.293,59.84 C16.267,57.52 19.896,57.748 22.177,56.948 C23.247,58.172 24.097,59.618 25.206,60.803 C24.986,61.668 25.705,62.981 26.996,62.317 C27.645,60.08 28.117,57.2 30.988,56.811 C31.009,69.858 30.984,84.289 31.263,97.147 C30.903,97.383 30.298,97.375 30.298,97.973 C30.244,100.368 30.39,102.317 31.125,104.305 C32.298,107.477 34.142,109.037 38.008,107.746 C40.63,106.871 42.804,103.998 42.137,101 C41.464,100.62 40.807,100.221 40.073,99.899 C39.525,101.35 38.585,103.479 36.494,103.065 C35.143,102.799 34.687,100.037 34.566,98.248 C34.388,95.596 34.556,92.34 34.429,89.438 C33.955,78.645 34.105,65.936 33.74,56.535 C35.278,57.155 36.093,58.496 37.044,59.701 C34.193,61.006 38.343,64.051 38.833,61.493 C39.039,60.422 38.056,60.284 38.146,59.565 C38.691,56.991 41.214,56.393 44.616,56.674 C46.013,57.842 45.923,60.09 46.542,61.768 C46.958,61.951 47.641,61.951 48.057,61.768 C48.617,61.36 48.542,60.153 48.195,59.565 C48.813,59.586 48.769,58.946 48.746,58.326 C51.266,55.951 57.144,55.936 57.693,60.116 C57.236,60.784 57.482,62.569 57.969,63.143 C60.285,64.241 60.787,60.313 59.483,59.428"
          id="Fill-459"
          fill="#1E1D1B"
        />
        <path
          d="M39.384,2.297 C39.91,3.011 40.092,4.068 40.348,5.051 C39.647,4.992 39.125,5.111 38.833,5.463 C38.518,4.456 39.301,3.362 39.384,2.297"
          id="Fill-460"
          fill="#FFFFFE"
        />
        <path
          d="M18.322,5.051 C19.072,6.357 19.998,8.097 19.562,10.144 C19.293,8.3 18.669,6.814 18.322,5.051"
          id="Fill-461"
          fill="#FFFFFE"
        />
        <path
          d="M40.21,5.739 C40.482,6.335 40.38,7.741 40.21,8.354 C39.787,8.252 39.947,8.731 39.523,8.63 C37.972,7.928 38.648,5.854 40.21,5.739"
          id="Fill-462"
          fill="#FFFFFE"
        />
        <path
          d="M17.91,7.528 C18.289,8.342 18.392,9.432 18.598,10.419 C18.252,10.305 18.056,10.042 17.634,10.007 C17.628,9.082 17.808,8.345 17.91,7.528"
          id="Fill-463"
          fill="#FFFFFE"
        />
        <path
          d="M17.634,11.107 C17.825,11.608 18.761,12.085 19.423,11.658 C19.74,12.074 18.954,12.559 18.736,12.896 C18.017,12.651 17.532,12.173 17.634,11.107"
          id="Fill-464"
          fill="#FFFFFE"
        />
        <path
          d="M31.263,11.519 C32.419,13.249 32.869,15.438 32.226,17.991 C30.594,17.172 31.158,13.641 31.263,11.519"
          id="Fill-465"
          fill="#FFFFFE"
        />
        <path
          d="M48.883,12.759 C49.409,13.84 49.907,14.949 50.123,16.339 C47.777,17.457 48.11,14.053 48.883,12.759"
          id="Fill-466"
          fill="#FFFFFE"
        />
        <path
          d="M47.919,17.165 C48.383,17.633 49.279,17.881 50.123,17.578 C50.129,19.352 47.589,18.979 47.919,17.165"
          id="Fill-467"
          fill="#FFFFFE"
        />
        <path
          d="M30.575,17.715 C30.895,18.266 31.431,18.602 31.675,19.229 C30.759,19.274 30.608,18.553 30.575,17.715"
          id="Fill-468"
          fill="#FFFFFE"
        />
        <path
          d="M3.868,21.571 C4.197,21.975 4.089,22.817 4.417,23.221 C4.046,23.537 3.53,23.709 3.317,24.184 C3.125,23.485 3.773,22.442 3.868,21.571"
          id="Fill-469"
          fill="#FFFFFE"
        />
        <path
          d="M22.865,22.672 C23.326,23.678 23.564,24.909 23.966,25.975 C23.513,26.569 22.746,26.625 22.177,26.112 C22.226,24.785 22.726,23.909 22.865,22.672 L33.5625,42.0585938"
          id="Fill-470"
          fill="#FFFFFE"
        />
        <path
          d="M39.248,24.047 C39.88,25.252 40.261,27.95 39.523,29.28 C38.913,29.338 38.651,29.049 38.558,28.59 C38.992,28.657 39.097,28.395 39.384,28.315 C39.365,27.694 38.69,27.725 38.283,27.489 C38.548,26.285 38.949,25.217 39.248,24.047"
          id="Fill-471"
          fill="#FFFFFE"
        />
        <path
          d="M4.417,24.184 C4.82,25.09 5.009,27.799 4.143,28.315 C2.095,27.604 3.171,24.893 4.417,24.184"
          id="Fill-472"
          fill="#FFFFFE"
        />
        <path
          d="M14.744,25.7 C15.251,26.676 15.919,28.727 14.468,29.141 C14.192,27.745 14.264,26.871 14.744,25.7"
          id="Fill-473"
          fill="#FFFFFE"
        />
        <path
          d="M24.105,27.213 C24.566,27.955 23.784,28.633 23.416,29.004 C22.33,29.08 22.213,28.186 22.039,27.352 C22.597,27.868 23.638,27.809 24.105,27.213"
          id="Fill-474"
          fill="#FFFFFE"
        />
        <path
          d="M15.431,29.828 C15.847,30.166 15.286,30.684 15.156,30.93 C14.843,30.784 14.656,30.514 14.605,30.106 C14.97,30.102 15.417,30.182 15.431,29.828"
          id="Fill-475"
          fill="#FFFFFE"
        />
        <path
          d="M56.592,31.618 C57.058,32.209 57.339,32.983 57.418,33.959 C57.093,33.78 56.467,33.901 56.179,33.684 C56.222,32.901 56.443,32.297 56.592,31.618"
          id="Fill-476"
          fill="#FFFFFE"
        />
        <path
          d="M56.455,38.914 C56.144,38.262 55.765,37.678 55.904,36.575 C56.263,36.151 56.978,35.36 57.418,35.473 C57.683,36.676 57.815,38.885 56.455,38.914"
          id="Fill-477"
          fill="#FFFFFE"
        />
        <path
          d="M36.769,56.26 C36.363,56.463 35.933,55.795 35.392,55.434 C34.462,54.815 33.378,54.225 31.675,54.196 C31.874,47.664 30.705,41.768 30.575,36.024 C31.779,35.784 32.689,38.606 33.19,39.879 C35.058,44.625 36.077,51.114 36.769,56.26"
          id="Fill-478"
          fill="#FFFFFE"
        />
        <path
          d="M41.45,36.713 C42.788,36.133 43.242,37.967 44.616,37.401 C48.769,40.032 52.473,43.795 55.216,48.276 C56.807,50.877 58.495,53.024 58.244,57.362 C56.962,56.164 55.49,55.16 53.15,55.022 C53.212,46.088 47.423,40.118 41.45,36.713"
          id="Fill-479"
          fill="#FFFFFE"
        />
        <path
          d="M13.091,56.123 C12.634,56.311 12.059,55.451 11.164,55.159 C10.269,54.866 9.15,54.891 7.998,54.883 C10.609,46.207 14.973,39.282 23.828,36.85 C17.645,40.67 14.971,48 13.091,56.123"
          id="Fill-480"
          fill="#FFFFFE"
        />
        <path
          d="M7.171,55.159 C5.124,55.084 4.012,56.68 3.179,57.362 C2.125,49.268 7.777,44.868 12.128,41.256 C13.628,40.01 15.173,39.385 16.945,38.778 C17.037,38.778 17.129,38.778 17.22,38.778 C12.303,42.67 8.339,47.516 7.171,55.159"
          id="Fill-481"
          fill="#FFFFFE"
        />
        <path
          d="M32.639,56.535 C33.35,56.584 32.938,57.903 32.915,58.739 C32.57,71.106 33.584,85.301 32.915,96.733 C32.171,90.545 32.501,83.629 32.501,76.909 C32.501,70.203 32.336,63.387 32.226,56.948 C32.201,56.649 32.339,56.51 32.639,56.535"
          id="Fill-482"
          fill="#FFFFFE"
        />
        <path
          d="M33.19,98.66 C32.743,99.635 32.664,100.979 32.501,102.239 C32.179,101.231 31.939,100.139 31.951,98.797 C32.505,98.893 32.736,98.666 33.19,98.66"
          id="Fill-483"
          fill="#FFFFFE"
        />
        <path
          d="M35.392,105.407 C35.653,105.651 36.302,105.506 36.769,105.543 C36.449,106.014 35.544,106.284 34.841,105.957 C34.927,105.676 35.537,105.918 35.392,105.407"
          id="Fill-484"
          fill="#FFFFFE"
        />
        <path
          d="M28.922,36.162 C26.016,40.368 24.475,47.133 24.792,55.022 C25.772,48.719 26.378,40.951 29.886,36.713 C29.769,42.434 30.669,48.315 30.849,54.196 C29.035,55.041 27.468,56.137 26.032,57.362 C24.867,56.53 24.353,54.951 22.865,54.471 C19.251,53.303 15.246,55.573 13.642,57.362 C14.492,53.043 16.253,48.395 18.185,44.559 C20.12,40.717 23.123,37.67 27.408,36.299 C27.56,36.768 26.591,37.243 26.032,37.815 C22.298,41.627 19.57,47.178 18.322,53.645 C19.586,52.858 19.678,50.573 20.387,48.826 C22.373,43.938 25.287,39.506 28.922,36.162"
          id="Fill-485"
          fill="#FFFFFE"
        />
        <path
          d="M38.697,104.03 C38.859,104.381 38.175,105.065 37.595,105.129 C37.551,104.532 36.791,104.649 36.218,104.58 C36.583,104.123 38.287,104.776 38.697,104.03"
          id="Fill-486"
          fill="#FFFFFE"
        />
        <path
          d="M32.639,36.024 C34.386,35.719 35.976,37.971 36.908,39.19 C39.586,42.701 41.602,47.582 42.414,52.543 C42.853,44.854 38.59,39.938 35.254,36.024 C43.11,35.741 44.24,42.182 47.369,46.625 C46.5,44.19 45.689,41.696 44.203,39.879 C48.74,42.592 51.4,48.375 52.738,54.746 C51.093,54.244 49.769,55.141 48.746,55.709 C48.533,53.674 48.664,51.295 47.919,49.789 C47.304,50.524 47.289,52.659 47.232,53.92 C47.185,54.953 47.27,55.94 47.093,56.811 C45.279,55.639 43.644,53.295 40.624,53.782 C38.694,54.094 38.123,55.67 37.044,57.086 C37.922,48.748 35.292,41.729 32.639,36.024"
          id="Fill-487"
          fill="#FFFFFE"
        />
      </g>
    </g>
  </svg>
)

UmbrelaIcon.propTypes = {
  className: PropTypes.string,
}

UmbrelaIcon.defaultProps = {
  className: '',
}

export default UmbrelaIcon
