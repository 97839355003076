import React, { Component } from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import Logo from 'components/Logo';

const StyledLogo = styled(Logo)`
  height: 30px;
`;

const LATITUDE = 54.3774367;
const LONGITUDE = 18.601005;

const API_KEY = 'AIzaSyBuxJ_RQFjnDDd8Wsyo_tZBf0jZ7GHqTa4';

const ZOOM_LEVEL = 17;

const AnyReactComponent = () => <StyledLogo withoutHover withText />;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: LATITUDE,
      lng: LONGITUDE,
    },
    zoom: ZOOM_LEVEL,
  };
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: API_KEY,
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent lat={LATITUDE} lng={LONGITUDE} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
