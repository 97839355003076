export const COACHING_BUSINESS_SECTION = 'coaching_business_section'
export const COACHING_LIFE_SECTION = 'coaching_life_section'
export const COACHING_LIFE_ADVANTAGES_SECTION =
  'coaching_life_advantages_section'
export const COACHING_BUSINESS_ADVANTAGES_SECTION =
  'coaching_business_advantages_section'

export const THERAPY_PRACTICAL_INFO_SECTION = 'therapy_practical_info_section'
export const THERAPY_WORK_AREA_SECTION = 'therapy_work_area_section'
export const THERAPY_METHODOLOGY_SECTION = 'therapy_methodology_section'

export const ABOUT_ME_SECTION = 'about_me_section'
export const ABOUT_COMPETENCES_SECTION = 'about_competences_section'
export const ABOUT_EXPERIENCE_SECTION = 'about_experience_section'

export const TOP_SECTION = 'top_section'
