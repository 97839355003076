export {default as App} from './App'

export {default as Footer} from './Footer'
export {default as Header} from './Header'
export {default as MobileMenu} from './MobileMenu'

// page bodies
export {default as AboutPageBody} from './AboutPageBody'
export {default as TherapyPageBody} from './TherapyPageBody'
export {default as CoachingPageBody} from './CoachingPageBody'
export {default as HomePageBody} from './HomePageBody'
export {default as ContactPageBody} from './ContactPageBody'
