import React from 'react'
import PropTypes from 'prop-types'

const PhoneIcon = ({className}) => (
  <svg height="55px" viewBox="0 0 45 73" version="1.1" className={className}>
    <title>Phone Icon</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="phone" fill="#464644">
        <path
          d="M42.6019196,64.907651 C41.587991,70.2448725 36.6161349,70.3892348 32.2011765,70.4310789 C25.1161601,70.4966348 18.0179668,71.1786944 10.9336439,70.7609503 C8.07910911,70.5928763 4.70928778,69.8571167 3.36454945,66.9370923 C2.55174216,65.1726639 2.66686674,62.7317551 2.56006442,60.8306358 C2.23827041,55.1544767 2.35131443,49.4566981 2.37003951,43.7749598 C2.51845313,37.4376631 2.47198718,31.1052482 2.21399716,24.7721359 C2.07529284,21.3855492 2.13979035,18.0254639 2.46505197,14.6500357 C2.83955362,10.762714 2.87561674,4.84734586 7.19209502,3.14010448 C10.0320659,2.01728643 14.0662809,2.40992404 17.1094535,2.30252406 C20.2386229,2.19303186 23.3691793,2.15606953 26.4990421,2.2578903 C29.4465088,2.35273704 32.6519655,2.33460457 35.5085809,3.12127462 C40.0566954,4.37311214 41.1261057,7.57976893 41.393805,11.9657334 C42.2614005,26.1927448 42.8217659,40.4860095 42.8266206,54.740917 C42.8280076,58.006853 43.2129121,61.6926254 42.6019196,64.907651 Z M44.9057982,54.0504885 C44.8967825,49.2188839 44.8080117,44.3872792 44.6658398,39.5577667 C44.3967534,30.3604223 44.0222517,21.151222 43.4743697,11.9657334 C43.0936264,5.59217175 40.6579786,1.73623321 34.0695236,0.721512541 C26.6966958,-0.413161361 18.9181578,0.0206230073 11.5057992,0.471842439 C8.17758917,0.674786572 4.78488163,1.00117095 2.76881442,4.02022647 C0.795052015,6.97581835 0.713216469,11.2188153 0.384487243,14.6500357 C-0.536509408,24.2804671 0.516256342,34.100592 0.289474787,43.7749598 C0.26866914,50.1359682 0.0599191463,56.5569532 0.623752186,62.9012239 C0.976061146,66.8652599 1.8006583,69.9059348 5.59006018,71.9221255 C8.21573286,73.3204176 12.2305293,72.9493994 15.0496945,72.9703215 C20.083274,73.0079813 25.1092249,72.6683462 30.1400304,72.5560644 C35.8733732,72.4291372 43.5381737,72.6990319 44.6679203,65.3351587 C45.2268987,61.691928 44.9127334,57.7257998 44.9057982,54.0504885 Z"
          id="Fill-80"
        />
        <path
          d="M17.7679589,6.99877122 C20.5876973,6.84697753 23.4074357,6.77503365 26.2307147,6.77898661 C27.255366,6.78056779 27.2574903,5.00173553 26.2307147,5.00015434 C23.4074357,4.99620138 20.5876973,5.06814526 17.7679589,5.21993895 C16.7468482,5.27369922 16.7411832,7.05332207 17.7679589,6.99877122"
          id="Fill-81"
        />
        <path
          d="M30.656416,6.71453353 C30.6753678,6.6955813 30.695181,6.6757676 30.7149942,6.65595391 C31.5979753,5.77295234 30.2274164,4.40150015 29.3444354,5.28536318 C29.3246221,5.30517688 29.3048089,5.32499057 29.2849957,5.3439428 C28.4020146,6.22780583 29.7725735,7.59839656 30.656416,6.71453353"
          id="Fill-82"
        />
        <path
          d="M33.6559113,6.71415922 C33.6757275,6.69520611 33.6955436,6.67539149 33.7144982,6.65557688 C34.5984706,5.77339578 33.2277102,4.40187986 32.3437378,5.28492246 C32.3239217,5.30559858 32.3049671,5.32455169 32.2851509,5.34436631 C31.4020401,6.22740891 32.7719389,7.59892483 33.6559113,6.71415922"
          id="Fill-83"
        />
        <path
          d="M26.1712811,66.8512332 C25.4922107,67.6213883 22.7110282,67.4523298 21.7067692,67.4650763 C20.9969563,67.4737976 18.0422488,67.6039457 17.8140702,67.1014665 C16.9334649,65.1680283 21.846819,65.5437138 22.5839587,65.5510933 C23.2630291,65.542372 27.5792931,65.2565828 26.1712811,66.8512332 Z M22.5839587,64.041643 C20.4401732,64.0188335 16.2789886,63.5465433 16.0098199,66.5506848 C15.7611463,69.3227065 20.302173,69.0174621 22.0538193,68.9631219 C23.7330496,68.9114652 27.8798876,69.1147378 27.9953433,66.6781495 C28.143591,63.5391637 24.7181798,64.0127957 22.5839587,64.041643 Z"
          id="Fill-84"
        />
        <path
          d="M22.427532,61.174308 C16.4966622,61.4862102 10.5798984,61.2957196 4.64832321,61.4157356 C4.43109073,52.9162285 4.90716841,44.4341656 5.08631467,35.9388451 C5.25417613,27.9591748 4.48398825,19.9774113 4.9064631,12.0082075 C16.2568601,12.1505521 27.6065518,11.6907233 38.9477799,11.5790805 C39.7087989,28.066631 39.6149939,44.6058164 40.3795394,61.0961581 C34.3887191,61.213383 28.4204683,60.8589171 22.427532,61.174308 Z M40.4274998,10.4912608 L40.4274998,10.490563 C40.4133938,10.4591635 40.3957613,10.4333461 40.3788341,10.4061331 C40.2652808,10.1751721 40.0487536,9.99724132 39.7087989,10.0000324 C27.8611652,10.0984176 16.0050679,10.6098813 4.14826533,10.4319505 C3.7892675,10.4263684 3.56357142,10.6210456 3.45707108,10.8715441 C3.45142868,10.8813129 3.44578628,10.8896861 3.44084918,10.8994548 C3.43097497,10.9231789 3.42744847,10.9476008 3.42110077,10.9720227 C3.39147816,11.0487771 3.36044495,11.1234382 3.35480254,11.2169391 C2.83358566,19.4485034 3.67289296,27.6954187 3.4993891,35.9388451 C3.31460043,44.7007128 2.80748955,53.4472297 3.07761954,62.2146795 C3.08185135,62.3688862 3.13051706,62.4902977 3.19610999,62.5977539 C3.30825273,62.8308083 3.52619051,63.0087391 3.87108233,62.9996681 C10.0629133,62.8447637 16.237817,63.0820047 22.427532,62.7442851 C28.6955354,62.4016813 34.9367374,62.8231329 41.205446,62.6528776 C41.3429796,62.648691 41.4586488,62.6131048 41.5588015,62.56147 C41.8183519,62.4602937 42.0165413,62.2321237 41.9989088,61.867889 C41.1807605,44.8570128 41.3161782,27.7931061 40.5022617,10.7850209 C40.4966193,10.6733781 40.4662914,10.5784817 40.4274998,10.4912608 Z"
          id="Fill-85"
        />
        <path
          d="M12.6825416,16.1973538 C11.2336466,17.4805548 10.0320261,19.0314793 9.10441247,20.7875145 C8.62523455,21.6943195 9.92375993,22.5118834 10.4022695,21.6050784 C11.2717402,19.9591553 12.3891537,18.5442515 13.745822,17.342375 C14.4936604,16.6795437 13.4250335,15.5402799 12.6825416,16.1973538"
          id="Fill-86"
        />
        <path
          d="M15.7982921,16.2609411 C14.9263214,17.3824231 14.0543506,18.5032042 13.1823799,19.6232843 C12.5839811,20.3922004 13.5988244,21.5129815 14.2017079,20.7391589 C15.0736786,19.6176769 15.9456494,18.4975968 16.8176201,17.3761148 C17.4160189,16.6071987 16.4011756,15.4871186 15.7982921,16.2609411"
          id="Fill-87"
        />
      </g>
    </g>
  </svg>
)

PhoneIcon.propTypes = {
  className: PropTypes.string,
}

PhoneIcon.defaultProps = {
  className: '',
}

export default PhoneIcon
