export default [
  {
    link: '/therapy/',
    title: 'Psychoterapia',
  },
  {
    link: '/coaching/',
    title: 'Coaching',
  },
  {
    link: '/about/',
    title: 'O mnie',
  },
  // {
  //   link: 'contact',
  //   title: 'Kontakt',
  // },
]
